
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'header-global-search-input cm_searchInput' }, 'Search by SKU, VIN or Keyword');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '5812'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '918'
        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
        _createElement('button', {
            'type': 'button',
            'title': 'Search',
            'aria-label': 'search button',
            'className': 'cm_search-box_submit button icon-left',
            'data-cm-role': 'add-query',
            'key': '5814'
        }, [
            _createElement('svg', {
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '12400'
            }, _createElement('path', { 'd': 'M19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L19.4697 20.5303ZM16.3336 15.273C16.0407 14.9801 15.5659 14.9801 15.273 15.273C14.9801 15.5659 14.9801 16.0408 15.273 16.3336L16.3336 15.273ZM10.5 17.25C6.77208 17.25 3.75 14.2279 3.75 10.5H2.25C2.25 15.0563 5.94365 18.75 10.5 18.75V17.25ZM3.75 10.5C3.75 6.77208 6.77208 3.75 10.5 3.75V2.25C5.94365 2.25 2.25 5.94365 2.25 10.5H3.75ZM10.5 3.75C14.2279 3.75 17.25 6.77208 17.25 10.5H18.75C18.75 5.94365 15.0563 2.25 10.5 2.25V3.75ZM17.25 10.5C17.25 14.2279 14.2279 17.25 10.5 17.25V18.75C15.0563 18.75 18.75 15.0563 18.75 10.5H17.25ZM20.5303 19.4697L16.3336 15.273L15.273 16.3336L19.4697 20.5303L20.5303 19.4697Z' })),
            _createElement('span', { 'key': '12402' }, 'Search')
        ])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function onError1_facetValue(e) {
                                        e.target.classList.add('cm_hide');
                                    }
                                    function mergeProps_facetValue(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                        'src': this.imageOrDefault(this.resizeImage(this.image || this.image1)),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From: ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                        'className': 'image-block',
                                        'key': '35'
                                    }, _createElement('img', {
                                        'src': this.imageUrl,
                                        'alt': 'Image of ' + this.facetValue,
                                        'onError': onError1_facetValue.bind(this)
                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '335'
                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                            'className': 'section-title-div',
                            'key': '94'
                        }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]